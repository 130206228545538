.background-animate {
    background-size: 400%;

    -webkit-animation: background-color 3s ease infinite;
    -moz-animation: background-color 3s ease infinite;
    animation: background-color 10s ease infinite;
}

@keyframes background-color {

    0%,
    100% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }
}